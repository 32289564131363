import {
    faChevronLeft,
    faChevronRight,
    faEnvelope,
    faExternalLinkSquareAlt,
    faThumbsUp,
    faTrophy
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
    faLinkedin,
    faYoutube,
    faGithub,
    faEnvelope,
    faThumbsUp,
    faTrophy,
    faChevronLeft,
    faChevronRight,
    faExternalLinkSquareAlt
);
